// Need to use the React-specific entry point to allow generating React hooks
import { IRole } from "@backend/model/role";
import { baseAPI } from "./baseAPI";
import { getApiKeyHeaders } from "./apiUtils";

const enchancedAPI = baseAPI.enhanceEndpoints({ addTagTypes: ["roles"] });

// Define a service using a base URL and expected endpoints
export const rolesAPI = enchancedAPI.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<IRole[], any>({
      query: () => {
        return {
          url: "organisation",
        };
      },
    }),
    getSingleRole: builder.query<
      IRole,
      {
        organisationId: string;
        email: string;
        apiKey?: string;
        courseId?: string;
        password?: string;
      }
    >({
      query: ({ organisationId, email, apiKey, courseId, password }) => {
        return {
          url: `organisation/${organisationId}/role/${email}`,
          headers:
            apiKey && email && courseId
              ? getApiKeyHeaders({
                  courseId,
                  email,
                  apiKey,
                  organisationId,
                  password,
                })
              : undefined,
        };
      },
      providesTags: ["roles"],
    }),
    listPeople: builder.query<IRole[], IRole>({
      query: ({ organisationId }) => {
        return {
          url: `organisation/${organisationId}/role`,
        };
      },
      providesTags: ["roles"],
    }),
    addRole: builder.mutation<any, IRole>({
      query: (params) => {
        return {
          url: `organisation/${params.organisationId}/role`,
          method: "POST",
          body: params,
        };
      },
      invalidatesTags: (res) => (res ? ["roles"] : []),
    }),
    editRole: builder.mutation<
      any,
      IRole & { apiKey?: string; courseId?: string; password?: string }
    >({
      query: (params) => {
        return {
          url: `organisation/${params.organisationId}/role/${params.email}/edit`,
          method: "PUT",
          headers:
            params.apiKey && params.email && params.courseId
              ? getApiKeyHeaders({
                  courseId: params.courseId,
                  email: params.email,
                  apiKey: params.apiKey,
                  organisationId: params.organisationId,
                  password: params.password,
                })
              : undefined,
          body: params,
        };
      },
      invalidatesTags: (res) => (res ? ["roles"] : []),
    }),
    deleteRole: builder.mutation<any, any>({
      query: ({ email, organisationId }) => {
        return {
          url: `organisation/${organisationId}/role/${email}/delete`,
          method: "DELETE",
        };
      },
      invalidatesTags: (res) => (res ? ["roles"] : []),
    }),
  }),
});

export const {
  useLazyGetRolesQuery,
  useAddRoleMutation,
  useEditRoleMutation,
  useListPeopleQuery,
  useDeleteRoleMutation,
  useGetSingleRoleQuery,
} = rolesAPI;
