export const enTranslation = {
  // People
  peopleTitle: "People",
  all: "All",
  employees: "Employees",
  contractors: "Contractors",
  visitors: "Visitors",
  employee: "Employee",
  contractor: "Contractor",
  visitor: "Visitor",
  firstName: "First name",
  lastName: "Last name",
  email: "Email",
  company: "Company",
  phoneNumber: "Phone number",
  taxNumber: "Tax number",
  userType: "User type",
  dateOfBirth: "Date of birth",
  addPerson: "+ Add Person",
  deletePerson: "- Delete Person",
  deleteConfirmation: "Are you sure you want to remove these people?",
  deletePartConfirmation: "Are you sure you want to delete?",
  cannotRemoveManager: "Cannot remove a manager.",
  invitePeople: "Invite people:",
  readedTos: "I have read and accepted",
  tos: "terms of services",
  personalInformation: "Personal information",
  occupationalSafetyCard: "Occupational safety card",
  // Courses
  coursesTitle: "Courses",
  courses: "Courses",
  newCourse: "+ New course",
  deleteCourseConfirmation: "Are you sure you want to remove this course?",
  addSlide: "+ Add Slide",
  backgroundImage: "Background image:",
  recurrency: "Recurrency:",
  none: "None",
  monthly: "Monthly",
  yearly: "Yearly",
  previousSlide: "Previous slide",
  nextSlide: "Next slide",
  chooseLayout: "Choose page layout",
  video: "Video",
  quiz: "Quiz",
  status: "Status",
  completed: "Completed",
  name: "Name",
  description: "Description",
  completedDate: "Completed d/m/y",
  addImage: "Add image",
  changeImage: "Change image",
  fillMode: "Fill mode",
  deleteSlide: "Delete Slide",
  organize: "Organize slides",
  moveUp: "Move up",
  moveDown: "Move down",
  newAnswer: "+ New answer",
  newQuestion: "+ New question",
  checkAnswers: "Check answers",
  deleteSlideConfirmation: "Are you sure you want to delete this slide?",
  textContent: "Text",
  imageContent: "Image",
  videoContent: "Video",
  quizContent: "Quiz",
  oneElementLayout: "One element",
  oneElementWithTitleLayout: "One element and title",
  twoColumnsWithTitleLayout: "Two elements and title",
  twoColumnsLayout: "Two elements",
  threeElementsWithTitleLayout: "Three elements and title",
  threeElementsLayout: "Three elements",
  generatePublicLink: "Generate public link for preview",
  result: "Result",
  passed: "Passed",
  failed: "Failed",
  required: "Required",
  tryAgain: "Try again",
  howManyQuestions: "How many questions are shown?",
  howManyCorrectAnswers: "How many correct answers are required?",
  send: "Send",
  slides: "Slides",
  info: "Info",
  Unfinished: "Unfinished",
  welcomeToCourse: "Welcome to the course",
  checkPassword: "Check the password",
  password: "Password",
  confirm: "Confirm",
  tosDisclaimer:
    "Before you can start the course, you must accept the terms of service and enter the course password you received in the email",
  // Common
  errorOccured: "An error occured",
  ok: "OK",
  cancel: "Cancel",
  edit: "Edit",
  save: "Save",
  back: "Back",
  logout: "Log out",
  login: "Login",
  logoutConfirmation: "Log out?",
  // Settings
  settings: "Settings",
  peopleInfo: "People info",
  isTaxNumberVisible: "User's tax number",
  isPhoneVisible: "User's phone",
  isCompanyVisible: "User's company",
  isSafetyCardVisible: "User's occupational safety card",
  isDateOfBirthVisible: "User's date of birth",
};
