const config = {
  baseUrl: "https://safety-master.azurewebsites.net/api/",
  publicCourseEmail: "test@inguider.fi",
  publicCoursePassword: "testingInguiderCourse",
};

if (process.env.NODE_ENV === "development") {
  const privateConfig = require("./private-config");
  console.log(privateConfig);
  Object.assign(config, privateConfig);
}

export default config;
