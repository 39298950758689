export const fiTranslation = {
  // People
  peopleTitle: "Henkilöt",
  all: "Kaikki",
  employees: "Työntekijät",
  contractors: "Urakoitsijat",
  visitors: "Vierailijat",
  employee: "Työntekijä",
  contractor: "Urakoitsija",
  visitor: "Vierailija",
  firstName: "Etunimi",
  lastName: "Sukunimi",
  email: "Sähköposti",
  company: "Yhtiö",
  phoneNumber: "Puhelinnumero",
  taxNumber: "Veronumero",
  userType: "Käyttäjän tyyppi",
  dateOfBirth: "Syntymäaika",
  addPerson: "+ Lisää henkilö",
  deletePerson: "- Poista henkilö",
  deleteConfirmation: "Haluatko varmasti poistaa kyseiset henkilöt?",
  deletePartConfirmation: "Haluatko varmasti poistaa tämän?",
  cannotRemoveManager: "Ylläpitäjää ei voi poistaa.",
  invitePeople: "Kutsu henkilö:",
  readedTos: "Olen lukenut ja hyväksyn",
  tos: "käyttöehdot",
  personalInformation: "Henkilötiedot",
  occupationalSafetyCard: "Työturvakortti",
  // Courses
  coursesTitle: "Perehdytykset",
  courses: "Perehdytykset",
  newCourse: "+ Uusi perehdytys",
  deleteCourseConfirmation: "Oletko varma että haluat poistaa tämän kurssin?",
  addSlide: "+ Lisää dia",
  backgroundImage: "Taustakuva:",
  recurrency: "Toistuva:",
  none: "Ei",
  monthly: "Kuukausittain",
  yearly: "Vuosittain",
  previousSlide: "Edellinen",
  nextSlide: "Seuraava",
  chooseLayout: "Valitse sivun tyyli",
  textOnly: "Teksti",
  leftImage: "Kuva ja teksti",
  video: "Video",
  quiz: "Kysely",
  status: "Tila",
  completed: "Suoritettu",
  name: "Nimi",
  description: "Kuvaus",
  completedDate: "Suoritettu p/k/v",
  addImage: "Lisää kuva",
  changeImage: "Vaihda kuva",
  fillMode: "Kuvan koko",
  deleteSlide: "Poista dia",
  organize: "Järjestä diat",
  moveUp: "Siirrä ylös",
  moveDown: "Siirrä alas",
  newAnswer: "+ Uusi vastaus",
  newQuestion: "+ Uusi kysymys",
  checkAnswers: "Tarkista vastaukset",
  deleteSlideConfirmation: "Haluatko varmasti poistaa tämän dian?",
  textContent: "Teksti",
  imageContent: "Kuva",
  videoContent: "Video",
  quizContent: "Kysely",
  oneElementLayout: "Yksi elementti",
  oneElementWithTitleLayout: "Yksi elementti ja otsikko",
  twoColumnsWithTitleLayout: "Kaksi elementtiä ja otsikko",
  twoColumnsLayout: "Kaksi elementtiä",
  threeElementsWithTitleLayout: "Kolme elementtiä ja otsikko",
  threeElementsLayout: "Kolme elementtiä",
  generatePublicLink: "Luo julkinen linkki esikatseluun",
  result: "Tulos",
  passed: "Hyväksytty",
  failed: "Hylätty",
  required: "Vaadittu",
  tryAgain: "Yritä uudelleen",
  howManyQuestions: "Kuinka monta kysymystä näytetään?",
  howManyCorrectAnswers: "Kuinka monta oikeaa vastausta vaaditaan?",
  send: "Lähetä",
  slides: "Diat",
  info: "Info",
  Unfinished: "Ei suoritettu",
  welcomeToCourse: "Tervetuloa suorittamaan kurssia",
  checkPassword: "Tarkista salasana",
  password: "Salasana",
  confirm: "Vahvista",
  tosDisclaimer:
    "Ennen kuin voit aloittaa kurssin, sinun tulee hyväksyä käyttöehdot ja syöttää kurssin salasana, jonka sait emailissa",
  // Common
  errorOccured: "Tapahtui virhe",
  ok: "OK",
  cancel: "Peruuta",
  edit: "Muokkaa",
  save: "Tallenna",
  back: "Takaisin",
  logout: "Kirjaudu ulos",
  login: "Kirjaudu sisään",
  logoutConfirmation: "Kirjaudu ulos?",
  // Settings
  settings: "Asetukset",
  peopleInfo: "Käyttäjien tiedot",
  isTaxNumberVisible: "Käyttäjän veronumero",
  isPhoneVisible: "Käyttäjän puhelinnumero",
  isCompanyVisible: "Käyttäjän yritys",
  isSafetyCardVisible: "Käyttäjän työturvakortti",
  isDateOfBirthVisible: "Käyttäjän syntymäaika",
};
