interface IApiKeyProps {
  organisationId: string;
  email?: string;
  courseId: string;
  apiKey?: string;
  password?: string;
}
export const getApiKeyHeaders = ({
  organisationId,
  email,
  courseId,
  apiKey,
  password,
}: IApiKeyProps) => {
  return {
    "X-OrganisationId": organisationId,
    "X-Email": email,
    "X-CourseId": courseId,
    "X-ApiKey": apiKey || "",
    "X-Password": password || "",
  };
};
