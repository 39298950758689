import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useGetCourseByIdQuery,
  useGetInvitationsQuery,
  useInviteToCourseMutation,
  useLazyGetCourseLinkQuery,
} from "../apis/coursesAPI";
import { selector } from "../store";
import { getOrganisation } from "../slices/organisationSlice";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Link,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useListPeopleQuery } from "../apis/rolesAPI";
import { ICourse, ICourseInvitation } from "@backend/model/course";
import Check from "@mui/icons-material/CheckCircle";
import Error from "@mui/icons-material/Error";
import colors from "../colors";
import { theme } from "../theme";
import { ViewContainer } from "./components/viewContainer";
import { useTranslation } from "react-i18next";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import config from "../config";
import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";
import DownloadIcon from "@mui/icons-material/Download";
import dayjs from "dayjs";

const CourseUser = (props: {
  invitation: ICourseInvitation;
  course: ICourse;
}) => {
  const { invitation, course } = props;
  const { t } = useTranslation();
  return (
    <Grid container key={`user-${invitation.email}`}>
      <Grid item xs={6}>
        <Typography variant="h6">{invitation.email}</Typography>
      </Grid>
      <Grid item xs={2}>
        {invitation.lastCompleted && invitation.isCompleted ? (
          <Check color="success" />
        ) : (
          <Error color="error" />
        )}
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body1">{invitation.lastCompleted ? dayjs(invitation.lastCompleted).format("DD.MM.YYYY") : t("Unfinished")}</Typography>
      </Grid>
    </Grid>
  );
};

export const CourseInvitations = () => {
  const org = selector((state) => getOrganisation(state));
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state } = useLocation();
  const { data: course, isFetching: loadingCourse } = useGetCourseByIdQuery({
    organisationId: org?.organisationId || "",
    courseId: state?.courseId,
  });
  const { data: invitations, isFetching: loadingInvitations } =
    useGetInvitationsQuery({
      courseId: state?.courseId,
      organisationId: org?.organisationId || "",
    });
  const { data: people, isFetching: loadingPeople } = useListPeopleQuery(org!, {
    skip: !org,
  });

  const [invite] = useInviteToCourseMutation();
  const [getLink] = useLazyGetCourseLinkQuery();
  const qrCodeRef = useRef<any>(null);

  const [email, setEmail] = useState("");
  const [publicLink, setPublicLink] = useState("");

  const createPublicLink = useCallback(async () => {
    const link = await getLink({
      courseId: state?.courseId,
      organisationId: org?.organisationId || "",
      email: config.publicCourseEmail,
    }).unwrap();
    setPublicLink(link.url);
  }, [getLink, state, org]);

  const downloadQRCode = useCallback(() => {
    htmlToImage
      .toPng(qrCodeRef.current)
      .then(function (dataUrl) {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "qr-code.png";
        link.click();
      })
      .catch(function (error) {
        console.error("Error generating QR code:", error);
      });
  }, []);

  const sendInvitations = useCallback(
    async (mailStr: string) => {
      const emails = mailStr.replaceAll(", ", ",").split(",");
      await invite({
        organisationId: org?.organisationId || "",
        courseId: state?.courseId,
        emails,
      }).unwrap();
      const link = await getLink({
        organisationId: org?.organisationId || "",
        courseId: state?.courseId,
        email: emails[0],
      }).unwrap();
      console.log(link);
    },
    [state, org, invite, getLink]
  );

  return (
    <ViewContainer loading={loadingInvitations || loadingPeople}>
      <Box flex={1} position={"relative"}>
        <Typography variant="h4" sx={{ marginBottom: theme.spacing(2) }}>
          {course?.name}
        </Typography>
        <Typography
          variant="body1"
          sx={{ marginBottom: theme.spacing(2), color: colors.textSecondary }}
        >
          {course?.description}
        </Typography>
        {course?.recurrency && (
          <Box flexDirection={"row"} display={"flex"}>
            <Typography
              variant="body1"
              sx={{
                marginBottom: theme.spacing(2),
                marginRight: theme.spacing(2),
              }}
            >
              {t("recurrency")}
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginBottom: theme.spacing(2), fontWeight: "bold" }}
            >
              {course?.recurrency}
            </Typography>
          </Box>
        )}
        <Button
          variant="text"
          style={{
            position: "absolute",
            top: theme.spacing(2),
            right: theme.spacing(2),
          }}
          endIcon={<QrCodeScannerIcon />}
          onClick={createPublicLink}
        >
          {t("generatePublicLink")}
        </Button>

        <Divider />

        <Typography variant="h6">{t("invitePeople")}</Typography>
        <Autocomplete
          disablePortal
          id="combo-box"
          options={people?.map((p) => p.email) || []}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Email" />}
          onChange={(e: any, value: string | null) => {
            if (!value) setEmail("");
            else setEmail(value);
          }}
        />
        <Button
          style={{
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
          }}
          variant="contained"
          disabled={!email}
          onClick={() => sendInvitations(email)}
        >
          {t('send')}
        </Button>

        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body1">{t("email")}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body1">{t("status")}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="body1">{t("completed")}</Typography>
          </Grid>
        </Grid>
        <Divider />
        {course &&
          invitations?.map((i) => (
            <CourseUser invitation={i} course={course} />
          ))}
      </Box>
      <Modal
        open={!!publicLink}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={() => setPublicLink("")}
      >
        <Card
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "80%",
            margin: theme.spacing(3),
            padding: theme.spacing(3),
            background: colors.backgroundSecondary,
          }}
        >
          <Link
            href={publicLink}
            rel="noreferrer"
            target="_blank"
            style={{
              textAlign: "center",
              marginRight: theme.spacing(3),
              marginLeft: theme.spacing(3),
            }}
          >
            {publicLink}
          </Link>
          <IconButton
            onClick={downloadQRCode}
            size="medium"
            style={{
              position: "absolute",
              top: theme.spacing(2),
              right: theme.spacing(2),
            }}
          >
            <DownloadIcon />
          </IconButton>
          <Box
            ref={qrCodeRef}
            style={{
              background: "white",
              marginTop: theme.spacing(2),
              padding: theme.spacing(2),
            }}
          >
            <QRCode value={publicLink} size={400} />
          </Box>
        </Card>
      </Modal>
    </ViewContainer>
  );
};
