import {
  Box,
  Button,
  Typography,
  Alert,
  Snackbar,
  Card,
  Modal,
} from "@mui/material";
import React, { useMemo, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDeleteRoleMutation, useListPeopleQuery } from "../apis/rolesAPI";
import { getOrganisation } from "../slices/organisationSlice";
import { selector } from "../store";
import { theme } from "../theme";
import {
  DataGrid,
  GridRowsProp,
  GridColDef,
  GridCellParams,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  useGridApiRef,
  GridRowId,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import colors from "../colors";
import { ViewContainer } from "./components/viewContainer";
import { useTranslation } from "react-i18next";
import { isSettingEnabled } from "../utils/settingUtils";
import { useListSettingsQuery } from "../apis/settingsAPI";

const EditButton = (params: GridCellParams) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Button
      variant="text"
      onClick={() =>
        navigate("/people/edit", {
          state: {
            person: params.row,
          },
        })
      }
    >
      {t("edit")}
    </Button>
  );
};

const CoursesButton = (params: GridCellParams) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
    <Button
      variant="text"
      onClick={() =>
        navigate("/people/courses", {
          state: {
            person: params.row,
          },
        })
      }
    >
      {t("courses")}
    </Button>
  );
};

const ConvertedDate = (params: GridCellParams) => {
  return (
    <Typography style={{ fontSize: 14 }}>
      {params.row.dateOfBirth
        ? dayjs(params.row.dateOfBirth).format("DD.MM.YYYY")
        : ""}
    </Typography>
  );
};

export const People = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [filter, setFilter] = useState<
    "all" | "visitor" | "contractor" | "employee"
  >("all");
  const org = selector((state) => getOrganisation(state));
  const {
    data: people,
    isFetching: loading,
    isLoading: peopleLoading,
  } = useListPeopleQuery(org!, {
    skip: !org,
  });
  const { data: settings, isFetching: loadingSettings } = useListSettingsQuery({
    organisationId: org?.organisationId || "",
  });

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);

  const [error, setError] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteErrorModal, setShowDeleteErrorModal] = useState(false);

  const [deleteRole] = useDeleteRoleMutation();

  const onDelete = useCallback(
    async (email: GridRowId) => {
      const role = people?.find((p) => p.email == email)?.role;
      const emailString = email.toString();
      if (org) {
        try {
          if (role === "user" && !loading) {
            await deleteRole({
              organisationId: org.organisationId,
              email: emailString,
            }).unwrap();
          } else {
            console.log("Can't delete a manager");
            setShowDeleteErrorModal(true);
          }
        } catch (err) {
          console.log("error at onDelete");
          setError(true);
        }
      }
    },
    [deleteRole, org, people, loading]
  );

  const onDeleteSelected = useCallback(
    async (emails: GridRowId[]) => {
      emails.forEach(async (email) => {
        try {
          console.log("delete " + email);
          await onDelete(email);
        } catch {
          console.log("error at onDeleteSelected");
          setError(true);
        }
      });
    },
    [onDelete]
  );

  const filteredPeople = useMemo(() => {
    if (filter === "all") return people;
    else return people?.filter((p) => p.userStatus === filter);
  }, [people, filter]);

  // Row and column -data for the table.
  const data: GridRowsProp =
    filteredPeople?.map((p) => {
      return {
        id: p.email || "",
        ...p,
        edit: t("edit"),
        courses: t("coursesTitle"),
      };
    }) || [];
  const columns: GridColDef[] = useMemo(() => {
    let result = [
      { field: "firstName", headerName: t("firstName"), width: 120 },
      { field: "lastName", headerName: t("lastName"), width: 120 },
      { field: "email", headerName: t("email"), width: 180 },
      { field: "company", headerName: t("company"), width: 120 },
      { field: "phoneNumber", headerName: t("phoneNumber"), width: 120 },
      { field: "taxNumber", headerName: t("taxNumber"), width: 80 },
      { field: "userStatus", headerName: t("userType"), width: 100 },
      {
        field: "dateOfBirth",
        headerName: t("dateOfBirth"),
        width: 100,
        renderCell: ConvertedDate,
      },
    ];

    if (!settings || !isSettingEnabled(settings, "companyVisible")) {
      result = result.filter((r) => r.field !== "company");
    }
    if (!settings || !isSettingEnabled(settings, "taxnumberVisible")) {
      result = result.filter((r) => r.field !== "taxNumber");
    }
    if (!settings || !isSettingEnabled(settings, "phoneVisible")) {
      result = result.filter((r) => r.field !== "phoneNumber");
    }
    if (!settings || !isSettingEnabled(settings, "dateOfBirthVisible")) {
      result = result.filter((r) => r.field !== "dateOfBirth");
    }

    return result;
  }, [t, settings]);

  // Checkboxes as the last column.
  const columnsWithCheckbox = React.useMemo(
    () => [
      ...columns,
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        width: 60,
      },
    ],
    [columns]
  );

  return (
    <ViewContainer loading={peopleLoading || loadingSettings}>
      <Box position={"relative"}>
        <Snackbar
          open={error}
          autoHideDuration={3000}
          onClose={() => setError(false)}
        >
          <Alert
            onClose={() => setError(false)}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {t("errorOccured")}
          </Alert>
        </Snackbar>
        <Typography variant="h4" style={{ paddingBottom: theme.spacing(4) }}>
          {t("peopleTitle")}
        </Typography>
        <Button
          onClick={() => setFilter("all")}
          variant={filter === "all" ? "contained" : "outlined"}
          sx={{ margin: theme.spacing(0, 1) }}
        >
          {t("all")}
        </Button>
        <Button
          onClick={() => setFilter("employee")}
          variant={filter === "employee" ? "contained" : "outlined"}
          sx={{ margin: theme.spacing(0, 1) }}
        >
          {t("employees")}
        </Button>
        <Button
          onClick={() => setFilter("contractor")}
          variant={filter === "contractor" ? "contained" : "outlined"}
          sx={{ margin: theme.spacing(0, 1) }}
        >
          {t("contractors")}
        </Button>
        <Button
          onClick={() => setFilter("visitor")}
          variant={filter === "visitor" ? "contained" : "outlined"}
          sx={{ margin: theme.spacing(0, 1) }}
        >
          {t("visitors")}
        </Button>
        {filteredPeople && (
          <DataGrid
            sx={{
              width: "calc(100vw - 100px)",
              borderStyle: "none",
              height: "75%",
              ".MuiDataGrid-cell": {
                borderColor: "#555",
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
            checkboxSelection
            rows={data}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              // Selecting a checkbox saves the email of every selected row on the list to rowSelectionModel-state
              setRowSelectionModel(newRowSelectionModel);
            }}
            columns={columnsWithCheckbox}
            onRowClick={(row) => {
              navigate("/people/person", {
                state: {
                  person: row.row,
                },
              });
            }}
            disableRowSelectionOnClick
          />
        )}
        <Button
          variant="text"
          style={{ paddingTop: theme.spacing(2) }}
          onClick={() => navigate("/people/add")}
        >
          {t("addPerson")}
        </Button>
        {rowSelectionModel.length > 0 ? (
          <Button
            variant="text"
            style={{ paddingTop: theme.spacing(2) }}
            onClick={() => {
              setShowDeleteModal(true);
            }}
          >
            {t("deletePerson")}
          </Button>
        ) : (
          <></>
        )}
        {/* Confirmation modal for deleting people */}
        <Modal
          open={showDeleteModal}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card
            style={{
              padding: theme.spacing(3),
              background: colors.backgroundSecondary,
            }}
          >
            <Typography>{t("deleteConfirmation")}</Typography>

            {rowSelectionModel.map((email) => {
              const person = people?.find((p) => p.email == email);
              return (
                <Typography>
                  {person?.firstName} {person?.lastName}
                </Typography>
              );
            })}

            <Button
              variant="text"
              style={{ paddingTop: theme.spacing(2) }}
              onClick={() => {
                console.log(rowSelectionModel);
                onDeleteSelected(rowSelectionModel);
                setShowDeleteModal(false);
              }}
            >
              {t("ok")}
            </Button>
            <Button
              variant="text"
              style={{ paddingTop: theme.spacing(2) }}
              onClick={() => {
                console.log(rowSelectionModel);
                setShowDeleteModal(false);
              }}
            >
              {t("cancel")}
            </Button>
          </Card>
        </Modal>
        {/* Error modal for trying to delete a manager */}
        <Modal
          open={showDeleteErrorModal}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card
            style={{
              padding: theme.spacing(3),
              background: colors.backgroundSecondary,
            }}
          >
            <Typography>{t("cannotRemoveManager")}</Typography>
            <Button
              variant="text"
              style={{ paddingTop: theme.spacing(2) }}
              onClick={() => {
                setShowDeleteErrorModal(false);
              }}
            >
              {t("ok")}
            </Button>
          </Card>
        </Modal>
      </Box>
    </ViewContainer>
  );
};
