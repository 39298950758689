import React, { useEffect, useReducer, useState } from "react";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "./App.css";

import { ThemeProvider } from "@mui/material/styles";
import { Provider, useDispatch } from "react-redux";
import { selector } from "./store";
import {
  AuthenticatedTemplate,
  IMsalContext,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { theme } from "./theme";
import { AuthenticatedRoutes, UnauthenticatedRoutes } from "./routes";
import { CssBaseline } from "@mui/material";
import { useLazyGetRolesQuery } from "./apis/rolesAPI";
import { getOrganisation, setOrganisation } from "./slices/organisationSlice";
import { IPublicClientApplication } from "@azure/msal-browser";
import { setBaseApiMsalInstance } from "./apis/baseAPI";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export default function App(props: { msalInstance: IPublicClientApplication }) {
  const { msalInstance } = props;
  const account = msalInstance.getActiveAccount();
  const org = selector((state) => getOrganisation(state));
  const dispatch = useDispatch();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  msalInstance.addEventCallback((c) => {
    if (c.eventType === "msal:loginSuccess") {
      forceUpdate();
    }
  });

  useEffect(() => setBaseApiMsalInstance(msalInstance), [msalInstance]);

  const [getRoles, { isFetching: rolesFetching }] = useLazyGetRolesQuery();

  useEffect(() => {
    const fetchOrganisation = async () => {
      try {
        console.log("fetch organisations");
        const roles = await getRoles({}).unwrap();

        dispatch(setOrganisation(JSON.parse(JSON.stringify(roles[0]))));
      } catch (err) {
        msalInstance.logout();
      }
    };
    if (account && !org && !rolesFetching) {
      fetchOrganisation();
    }
    console.log("check if fetch is necessary");
  }, [account, org, rolesFetching, dispatch, getRoles, msalInstance]);

  return (
    <MsalProvider instance={msalInstance}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AuthenticatedTemplate>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            {!!account && <AuthenticatedRoutes organisation={org} />}
          </ThemeProvider>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <UnauthenticatedRoutes />
          </ThemeProvider>
        </UnauthenticatedTemplate>
      </LocalizationProvider>
    </MsalProvider>
  );
}
