import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { t } from "i18next";
import { IContentType, ISlideContent } from "@backend/model/slide";
import {
  Box,
  Button,
  Card,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import {
  ContentCopy,
  DeleteOutlined,
  EditOutlined,
  RemoveOutlined,
  AspectRatio,
  PlayArrow,
} from "@mui/icons-material";
import { TextContent } from "./slideTextContent";
import { ImageContent } from "./slideImageContent";
import { VideoContent } from "./slideVideoContent";
import { QuizContent } from "./slideQuizContent";
import { theme } from "../../theme";

import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import SubjectOutlinedIcon from "@mui/icons-material/SubjectOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import colors from "../../colors";

interface ISlideContentSelectorProps {
  setContent: (
    index: number,
    content: string,
    contentType?: string,
    metadata?: string
  ) => void;
  content: ISlideContent;
  index: number;
  edit?: boolean;
  thumbnail?: boolean;
  onSetReady: (index: number) => void;
}

export const SlideContentSelector = (props: ISlideContentSelectorProps) => {
  const { index, setContent, onSetReady, content } = props;
  const setReadyCallback = useCallback(() => {
    onSetReady(index);
  }, [index, onSetReady]);

  const [autoOpenImage, setAutoOpenImage] = useState(false);
  useEffect(() => {
    if (autoOpenImage) setAutoOpenImage(false);
  }, [autoOpenImage]);

  const onFillModeChangedCallback = useCallback(
    (fillMode: string) =>
      setContent(index, content.data, "image", JSON.stringify({ fillMode })),
    [content, index, setContent]
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const actions: Array<{
    icon: ReactNode;
    onClick: () => void;
  }> = [];
  if (props.content && props.edit && props.content.contentType !== "unknown") {
    actions.push({
      icon: <DeleteOutlined style={{ zIndex: 11 }} />,
      onClick: () => setShowDeleteModal(true),
    });
  }

  if (props.content && props.edit && props.content.contentType === "image") {
    actions.push({
      icon: <EditOutlined />,
      onClick: () => setAutoOpenImage(true),
    });
    actions.push({
      icon: <AspectRatio />,
      onClick: () => {
        const metadata = JSON.parse(content.metadata || "{}");
        onFillModeChangedCallback(
          metadata.fillMode === "cover" ? "contain" : "cover"
        );
      },
    });
  }

  return (
    <Box flex={1} display={"flex"} height={"100%"} position={"relative"}>
      {/* Confirmation modal for deleting slide content */}
      <Modal
        open={showDeleteModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card
          style={{
            padding: theme.spacing(3),
            background: colors.backgroundSecondary,
          }}
        >
          <Typography>{t("deletePartConfirmation")}</Typography>
          <Button
            variant="text"
            style={{ paddingTop: theme.spacing(2) }}
            onClick={() => {
              setContent(index, "", "unknown", undefined); // Set content to empty
              setShowDeleteModal(false);
            }}
          >
            {t("ok")}
          </Button>
          <Button
            variant="text"
            style={{ paddingTop: theme.spacing(2) }}
            onClick={() => {
              setShowDeleteModal(false);
            }}
          >
            {t("cancel")}
          </Button>
        </Card>
      </Modal>
      {props.content.contentType === "unknown" && props.edit && (
        <Box
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Button
            startIcon={<SubjectOutlinedIcon />}
            style={{ width: 150, height: 40, marginBottom: theme.spacing(1) }}
            variant="contained"
            onClick={() => setContent(index, "", "text")}
          >
            {t("textContent")}
          </Button>
          <Button
            startIcon={<ImageOutlinedIcon />}
            style={{ width: 150, height: 40, marginBottom: theme.spacing(1) }}
            variant="contained"
            onClick={() => {
              setAutoOpenImage(true);
              setContent(index, "", "image");
            }}
          >
            {t("imageContent")}
          </Button>
          <Button
            startIcon={<VideocamOutlinedIcon />}
            style={{ width: 150, height: 40, marginBottom: theme.spacing(1) }}
            variant="contained"
            onClick={() => setContent(index, "", "video")}
          >
            {t("videoContent")}
          </Button>
          <Button
            startIcon={<QuizOutlinedIcon />}
            style={{ width: 150, height: 40, marginBottom: theme.spacing(1) }}
            variant="contained"
            onClick={() => setContent(index, "", "quiz")}
          >
            {t("quizContent")}
          </Button>
        </Box>
      )}
      {props.content.contentType === "text" && (
        <TextContent
          content={props.content.data}
          edit={props.edit}
          onValueChanged={(val) => setContent(index, val)}
          onSetReady={setReadyCallback}
        />
      )}
      {props.content.contentType === "image" && (
        <ImageContent
          content={props.content.data}
          edit={props.edit}
          onValueChanged={(val) => {
            setContent(index, val);
            console.log("image ", index, " changed");
          }}
          onFillModeChanged={onFillModeChangedCallback}
          metadataStr={props.content.metadata}
          onSetReady={setReadyCallback}
          autoOpen={autoOpenImage}
        />
      )}
      {props.content.contentType === "video" &&
        (props.thumbnail ? (
          <PlayArrow
            sx={{
              alignSelf: "center",
              width: "50%",
              height: "50%",
              marginLeft: 18,
            }}
          />
        ) : (
          <VideoContent
            content={props.content.data}
            edit={props.edit}
            onValueChanged={(val) => setContent(index, val)}
            onSetReady={setReadyCallback}
          />
        ))}
      {props.content.contentType === "quiz" && (
        <QuizContent
          content={props.content.data}
          edit={props.edit}
          onValueChanged={(val) => {
            setContent(index, val);
          }}
          thumbnail={props.thumbnail}
          index={props.index}
          onSetReady={setReadyCallback}
        />
      )}
      <Box
        display={"flex"}
        flexDirection={"row-reverse"}
        position={"absolute"}
        top={theme.spacing(2)}
        right={theme.spacing(1)}
      >
        {actions.map((a) => (
          <IconButton
            onClick={a.onClick}
            style={{
              borderRadius: 30,
              backgroundColor: "rgba(0,0,0,0.6)",
              marginRight: theme.spacing(1),
            }}
          >
            {a.icon}
          </IconButton>
        ))}
      </Box>
    </Box>
  );
};
