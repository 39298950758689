// Need to use the React-specific entry point to allow generating React hooks
import { IAddCourseDto } from "@backend/dto/addCourseDto";
import { baseAPI } from "./baseAPI";
import type { ISetting } from "@backend/model/setting";
import { getApiKeyHeaders } from "./apiUtils";

const enchancedAPI = baseAPI.enhanceEndpoints({
  addTagTypes: ["settings"],
});

// Define a service using a base URL and expected endpoints
export const settingsAPI = enchancedAPI.injectEndpoints({
  endpoints: (builder) => ({
    listSettings: builder.query<
      ISetting[],
      {
        organisationId: string;
        apiKey?: string;
        email?: string;
        courseId?: string;
      }
    >({
      query: ({ organisationId, apiKey, email, courseId }) => ({
        url: `organisation/${organisationId}/settings`,
        headers:
          apiKey && email && courseId
            ? getApiKeyHeaders({ courseId, email, apiKey, organisationId })
            : undefined,
      }),
      providesTags: ["settings"],
    }),
    updateSettings: builder.mutation<
      boolean,
      { settings: ISetting[]; organisationId: string }
    >({
      query: ({ settings, organisationId }) => ({
        url: `organisation/${organisationId}/settings`,
        method: "PUT",
        body: settings,
      }),
      invalidatesTags: (res) => (res ? ["settings"] : []),
    }),
  }),
});

export const { useListSettingsQuery, useUpdateSettingsMutation } = settingsAPI;
